import { Bus, Channel } from '../bus'
import {APIClient} from '../infrastructure/api_client'
import BuildCallback from '../infrastructure/buildCallback'

export default class Solicitude {
  constructor() {
    this.channel = Channel("show-solicitude")
    this.client = APIClient
    this.buildCallback = BuildCallback

    this.subscribe()
  }

  subscribe() {
    Bus.subscribe("get.solicitude", this.getSolicitude.bind(this))
    Bus.subscribe("create.solicitude", this.createSolicitude.bind(this))
    Bus.subscribe("update.solicitude", this.updateSolicitude.bind(this))
    Bus.subscribe("delete.solicitude", this.deleteSolicitude.bind(this))
    Bus.subscribe("create.subject", this.createSubject.bind(this))
    Bus.subscribe("update.subject", this.updateSubject.bind(this))
    Bus.subscribe("close.subject", this.closeSubject.bind(this))
    Bus.subscribe("delete.attachment", this.deleteAttachment.bind(this))
    Bus.subscribe("delete.subject", this.deleteSubject.bind(this))
    Bus.subscribe("remove.subject.closed.status", this.removeSubjectClosedStatus.bind(this))
    Bus.subscribe("check.email.to.share", this.checkEmailToShare.bind(this))
    Bus.subscribe("share.subject", this.shareSubject.bind(this))

    this.channel.subscribe("get.solicitude", this.getASolicitude.bind(this))
    this.channel.subscribe("delete.subject", this.deleteASubject.bind(this))
    this.channel.subscribe("get.is.coordinator", this.checkCoordinator.bind(this))

  }

  checkCoordinator(user_id){
    const callback = this.channelCallback('got.is.coordinator')
    const url = 'is-user-coordinator'
    APIClient.hit(url, { user_id }, callback)
  }

  shareSubject(payload){
    const callback = this.buildCallback('shared.subject')
    const url = 'create-solicitude-from-subject'
    APIClient.hit(url, payload, callback)
  }

  checkEmailToShare(email) {
    const callback = this.buildCallback('checked.email.to.share')
    const url = 'check-authorized-email'
    APIClient.hit(url, { email }, callback)
  }

  getASolicitude(id) {
    const url = 'retrieve-solicitude'
    const callback = this.channelCallback('got.solicitude')
    APIClient.hit(url, { id }, callback)
  }

  deleteASubject(id) {
    let url = 'delete-subject'
    let callback = this.channelCallback('deleted.subject')
    APIClient.hit(url, { subjectId: id }, callback)
  }

  deleteAttachment(payload) {
    const url = 'delete-attachment'
    const callback = this.buildCallback('deleted.attachment')
    APIClient.hit(url, payload, callback)
  }

  channelCallback(signal) {
    return (response) => {
      this.channel.publish(signal, response)
    }
  }

  createSubject(payload) {
    let callback = this.buildCallback('subject.created')
    let body = payload
    let url = 'create-subject'
    this.client.hit(url, body, callback)
  }

  updateSubject(payload) {
    let callback = this.buildCallback('subject.updated')
    let body = payload
    let url = 'update-subject'
    this.client.hit(url, body, callback)
  }

  closeSubject(payload) {
    let callback = this.buildCallback('subject.closed')
    let body = payload
    let url = 'close-subject'
    this.client.hit(url, body, callback)
  }

  deleteSolicitude(payload){
    let callback = this.buildCallback('deleted.solicitude')
    let body = {id: payload}
    let url = 'delete-solicitude'
    this.client.hit(url, body, callback)
  }

  deleteSubject(id){
    let callback = this.buildCallback('deleted.subject')
    let url = 'delete-subject'
    this.client.hit(url, { subjectId: id }, callback)
  }

  removeSubjectClosedStatus(id){
    let callback = this.buildCallback('subject.updated')
    let url = 'remove-closed-status'
    this.client.hit(url, { id: id }, callback)
  }

  getSolicitudesList(user_id) {
    let callback = this.buildCallback('got.solicitudes-list')
    let body = {user_id: user_id}
    let url = 'retrieve-solicitudes-by-user'
    this.client.hit(url, body, callback)
  }

  getSolicitude(data){
    let callback = this.buildCallback('got.solicitude')
    let body = {id: data.id}
    let url = 'retrieve-solicitude'
    this.client.hit(url, body, callback)
  }

  createSolicitude(payload) {
    let callback = this.buildCallback('created.solicitude')
    let body = payload
    let url = 'create-solicitude'
    this.client.hit(url, body, callback)
  }

  updateSolicitude(payload) {
    let callback = this.buildCallback('updated.solicitude')
    let body = payload
    let url = 'update-solicitude'
    this.client.hit(url, body, callback)
  }
}
