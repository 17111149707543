import {Bus} from '../bus'
import userSession from '../infrastructure/user_session'

const authorization = _ => {

  const handleTokenCheck = payload => {
    if(payload.error){
      window.location.href = "/login.html"
    }
    userSession.token(payload.token)
  }

  Bus.subscribe("got.user.token.check", handleTokenCheck)

  return {
    check: _ => {
      if (window.location.href.indexOf("login") > -1) {
        return
      }

      const data = {
        token: userSession.token(),
        exp: userSession.expiration()
      }

      Bus.publish("check.user.token", data)
    }
  }

}

export default authorization
