import {Bus} from '../bus'
import {APIClient} from '../infrastructure/api_client'
import BuildCallback from '../infrastructure/buildCallback'

export default class Logins {
  constructor() {
    this.client = APIClient
    this.buildCallback = BuildCallback
    this.subscribe()
  }

  subscribe() {
    Bus.subscribe("authorize.user", this.authorizeUser.bind(this))
    Bus.subscribe("check.user.token", this.checkAuthorization.bind(this))
  }
  
  authorizeUser(payload){
    let callback = this.buildCallback('got.user.authorization')
    let body = payload
    let url = 'authorization'
    this.client.hit(url, body, callback)
  }
  
  checkAuthorization(payload){
    let callback = this.buildCallback('got.user.token.check')
    let body = payload
    let url = 'check-authorization'
    this.client.hit(url, body, callback)
  }

}
