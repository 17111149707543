import { Bus } from '../bus'

export default (function(){
  const ISTAS_DOMAIN = "istas.ccoo.es"
  const SPL_EMAIL = "jose.sanchis.perez.de.leon@gmail.com"
  const expiration = (minutes = 4) => {
    const timeout_seconds = minutes * 60
    const current_seconds = Math.round(new Date().getTime() / 1000)

    return current_seconds + timeout_seconds
  }

  return {
    expiration,

    token: token => (token
      ? window.sessionStorage.setItem("authentication_token", token)
      : window.sessionStorage.getItem("authentication_token")
    ),

    name: name => (name
      ? window.sessionStorage.setItem("user_name", name)
      : window.sessionStorage.getItem("user_name")
    ),

    imageUrl: image_url => (image_url
      ? window.sessionStorage.setItem("user_image_url", image_url)
      : window.sessionStorage.getItem("user_image_url")
    ),

    email: email => (email
      ? window.sessionStorage.setItem("user_email", email)
      : window.sessionStorage.getItem("user_email") || ""
    ),

    isAdmin(){
      const email = window.sessionStorage.getItem("user_email")
      if (!email) {
        return false
      }
      return email.includes(ISTAS_DOMAIN) || email.includes(SPL_EMAIL)
    },

    authorize(email) {
      Bus.publish('authorize.user', { email, exp: expiration() })
    },

    setUser({ name, imageUrl, email }) {
      window.sessionStorage.setItem('user_name', name)
      window.sessionStorage.setItem('user_image_url', imageUrl)
      window.sessionStorage.setItem('user_email', email)
    },

    belongsToOrganization(email){
      const currentUser = this.email()
      const currentDomain = this.extractDomain(currentUser)
      const domain = this.extractDomain(email)

      const belongs = (currentDomain == domain)

      return belongs
    },

    extractDomain(email){
      let domain = email.split('@')[1]

      if (domain == undefined) { domain = '' }

      return domain
    },

    isCurrentUser(email) {
      const currentUser = this.email()

      return (currentUser == email)
    }
  }
})()
