import Vue from "vue"
import VueRouter from "vue-router"

import translations from "./services/translations"
import authorization from './components/authorization'
import Authorizations from './services/authorizations'
import Solicitude from './services/solicitude'

Vue.use(VueRouter)

new Authorizations()
translations.retrieve()

module.exports = {
  start: _ => {
    new Solicitude()
    authorization().check()

    const navbar = () => import(/* webpackChunkName: "navbar" */ './components/navbar')
    const linkbar = () => import(/* webpackChunkName: "linkbar" */ './components/linkbar')
    const solicitude = () => import(/* webpackChunkName: "solicitude" */ './components/solicitude')
    const solicitudesList = () => import(/* webpackChunkName: "solicitudes-list" */ './components/solicitudes-list')
    const subjects = () => import(/* webpackChunkName: "subjects" */ './components/subjects')
    const subjectsList = () => import(/* webpackChunkName: "subjects-list" */ './components/subjects-list')
    const showSolicitude = () => import(/* webpackChunkName: "show-solicitude" */ './components/show-solicitude')
    const usersManager = () => import(/* webpackChunkName: "users-manager" */ './components/users-manager')
    const catalogsManager = () => import(/* webpackChunkName: "catalogs-manager" */ './components/catalogs-manager')
    const subjectsLot = () => import(/* webpackChunkName: "package" */ './components/subjects-lot')

    const routes = [{
      path: '/',
      component: solicitude
    }, {
      path: '/solicitude',
      component: solicitude
    }, {
      path: '/solicitude/:id',
      component: solicitude
    }, {
      path: '/solicitudes-list',
      component: solicitudesList
    }, {
      path: '/subjects',
      component: subjects
    }, {
      path: '/subjects/:solicitude_id',
      component: subjects
    }, {
      path: '/subjects-list',
      component: subjectsList
    }, {
      path: '/show-solicitude/:id',
      component: showSolicitude
    }, {
      path: '/users-manager',
      component: usersManager
    }, {
      path: '/catalogs-manager',
      component: catalogsManager
    }, {
      path: '/subjects-lot',
      component: subjectsLot
    }]

    const router = new VueRouter({ routes })

    return new Vue({
      router,
      components: { navbar, linkbar }
    }).$mount('#app')
  }
}
